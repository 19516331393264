<template>
  <div>
    <div class="mb-5">
      <b-modal id="admin-scenario-weekly-rating-modal" title="Admin Scenario Weekly Rating" size="lg" hide-footer>
        <div class="modal-content bg-white rounded">
          <div class="card">
            <div class="card-body">
              <div class="row mb-3" v-for="(weekly_rating, index) in weekly_ratings" :key="index">
                <template v-if="weekly_rating.weekly_ratings.length > 0">
                  <div class="col-md-4">
                    <strong>{{ weekly_rating.name }}</strong>
                  </div>
                  <div class="col-md-4">
                    <strong>{{ weekly_rating.weekly_ratings[0].average_rating }}</strong>
                  </div>
                  <div class="col-md-12" v-for="(admin_scenario_sub_accessory, index) in weekly_rating.admin_scenario_sub_accessories" :key="index">
                    <div class="row" style="border-bottom: 1px solid black;">
                      <div class="col-md-4">
                        {{admin_scenario_sub_accessory.name }}
                      </div>
                      <div class="col-md-2">
                        {{admin_scenario_sub_accessory.weekly_rating.rating }}
                      </div>
                      <div class="col-md-6">
                        {{admin_scenario_sub_accessory.weekly_rating.rating_comment }}
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <a-skeleton active :loading="loading"></a-skeleton>
              <h5 class="text-center text-secondary" v-if="weekly_ratings.length === 0 && !flag">
                <Empty :image="simpleImage"></Empty>
              </h5>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import { Empty } from 'ant-design-vue'

export default {
  name: 'create',
  components: { Empty },
  props: ['weekly_ratings'],
  data() {
    return {
      loading: false,
      loader: false,
      flag: false,
      simpleImage: Empty.PRESENTED_IMAGE_SIMPLE,
    }
  },
}
</script>

<style scoped>

</style>
